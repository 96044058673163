import Swiper from 'swiper'
import { Navigation, Pagination, A11y, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';


export default class LinkCarousel {
    constructor($el) {

        this.$el = $el;

        this.swiper = new Swiper(this.$el, {
            modules: [Navigation, Pagination, Keyboard, A11y],
            slidesPerView: 1,
            spaceBetween: 39,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                991: {
                    slidesPerView: 3,
                },
            },
            Keyboard: {
                enabled: true,
                onlyInViewport: true,
            },
            A11y: {
                containerMessage: 'Carousel container',
                nextSlideMessage: 'Volgende slide',
                lastSlideMessage: 'Laatste slide',
            },
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        // console.log(this.$el);

        // this.$el.addEventListener('keydown', (e) => {
        //     console.log(e.keyCode);
        //     if (e.keyCode === 37) {
        //         this.swiper.slidePrev();

        //         console.log('prev');
        //     }
        //     if (e.keyCode === 39) {
        //         this.swiper.slideNext();

        //         console.log('next');
        //     }
        // });
    }
}