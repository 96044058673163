export function getKeyboardFocusableElements(element = document) {
	return [
		...element.querySelectorAll(
			'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
		),
	].filter(el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'));
}

export function selectMatchingVideoSource(video) {
	return new Promise((resolve) => {
		if(!video) {
			return;
		}
		const rect = video.getBoundingClientRect();

		const clientWidth = rect.width * window.devicePixelRatio;
		const clientHeight = rect.height * window.devicePixelRatio;
		const clientRatio = clientWidth/clientHeight;

		const videoWidth = video.getAttribute('width');
		const videoHeight = video.getAttribute('height');
		const videoRatio = videoWidth/videoHeight;

		let width = '';
		if(clientRatio>videoRatio) {
			width = clientWidth;
		} else {
			width = clientHeight*videoRatio;
		}

		let matchedSource = null;
		let prevDiff = 999999;

		// Loop over all video sources
		const totalSourceCount = video.children.length;
		let checkedSourcesCount = 0;
		const checkSources = new Promise((resolve, reject) => {
			Array.from(video.children).forEach(source => {
				// Get source resolution (provided by data attribute)
				let sourceWidth = source.dataset.sourcewidth;

				// Compare source resolution to video element width
				let currentDiff = sourceWidth - width;
				if (currentDiff <= 0) {
					currentDiff = currentDiff * -1;
				}
				if (currentDiff <= prevDiff) {
					matchedSource = source;
				}
				prevDiff = currentDiff;

				setTimeout(() => {
					checkedSourcesCount++;
					if (checkedSourcesCount >= totalSourceCount) {
						resolve('Checked All Sources');
					}
				}, 0);
			});
		});

		checkSources.then(() => {
			if(video.src!=matchedSource.src) {
				video.pause();
				video.src = matchedSource.src;
				resolve();
			}
		});

	});
}