import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import objectFitImages from 'object-fit-images';
import Carousel from './components/Carousel';
import MixFilter from './components/Filter';
import FilterMenu from './components/FilterMenu';
import LinkCarousel from './components/LinkCarousel';
import { getKeyboardFocusableElements } from './utils';

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Starter = {
		// All pages
		common: {
			init: function () {
				document.dispatchEvent(new Event('bless'));

				const carousel = document.querySelector('[data-carousel]');
				if (carousel) {
					new Carousel(carousel);
				}

				const linkCarousels = document.querySelectorAll('[data-link-carousel]');
				if (linkCarousels) {
					linkCarousels.forEach((el) => {
						new LinkCarousel(el);
					});
				}

				const filter = document.querySelector('[data-filter-form]');
				if (filter) {
					new MixFilter(filter);
					new FilterMenu();
				}

				// Assign Breakpoint Bodyclasses
				function assign_bootstrap_mode() {
					const width = $(window).width();
					var mode = '';
					if (width < 768) {
						mode = 'mode-xs';
					} else if (width < 992) {
						mode = 'mode-sm';
					} else if (width < 1200) {
						mode = 'mode-md';
					} else if (width > 1200) {
						mode = 'mode-lg';
					}
					$('body')
						.removeClass('mode-xs')
						.removeClass('mode-sm')
						.removeClass('mode-md')
						.removeClass('mode-lg')
						.addClass(mode);
				}
				assign_bootstrap_mode();
				$(window).on('resize', function () {
					assign_bootstrap_mode();
				});

				//Mobile menu

				const hamburger = document.querySelector('[data-hamburger]');


				$('[data-hamburger]').on('click', function () {
					if (
						$('body').hasClass('mode-xs') ||
						$('body').hasClass('mode-sm') ||
						$('body').hasClass('mode-md')
					) {
						if ($('body').hasClass('menu-active')) {
							$('body').removeClass('menu-active');
							$('.site-header__menu').removeClass('show_submenu');
							$('.menu-item-has-children').removeClass('current_submenu');

							hamburger.setAttribute('aria-expanded', 'false');
						} else {
							$('body').addClass('menu-active');
							hamburger.setAttribute('aria-expanded', 'true');

						}
					}
				});

				const menu = document.querySelector('[data-menu]');
				const focusableElements = getKeyboardFocusableElements(menu);

				if(focusableElements.length) {
					this.firstElement = focusableElements[0];
					this.lastElement = focusableElements[focusableElements.length - 1];
				}

				window.addEventListener('keydown', e => {
					if (e.key === 'Escape') {
						if ($('body').hasClass('menu-active')) {
							hamburger.setAttribute('aria-expanded', 'false');
							$('body').removeClass('menu-active');
							$('.site-header__menu').removeClass('show_submenu');
							$('.menu-item-has-children').removeClass('current_submenu');

							hamburger.focus();
						}

						if (e.key === 'Tab' || e.key === 9) {
							if (e.shiftKey) { /* shift + tab */
								if (document.activeElement === this.firstElement) {
									this.lastElement.focus();
									e.preventDefault();
								}
							} /* tab */ else {
								if (document.activeElement === this.lastElement) {
									this.firstElement.focus();
									e.preventDefault();
								}
							}
						}
					}
				});


				$('.menu-item-has-children').on('click', function (e) {
					if (
						$('body').hasClass('mode-xs') ||
						$('body').hasClass('mode-sm') ||
						$('body').hasClass('mode-md')
					) {
						e.preventDefault();
						$('.site-header__menu').addClass('show_submenu');
						$(this).addClass('current_submenu');
					}
				});

				$('.sub-menu li a').on('click', function (e) {
					if (
						$('body').hasClass('mode-xs') ||
						$('body').hasClass('mode-sm') ||
						$('body').hasClass('mode-md')
					) {
						e.stopPropagation();
					}
				});

				$('.mo-prev').on('click', function (e) {
					if (
						$('body').hasClass('mode-xs') ||
						$('body').hasClass('mode-sm') ||
						$('body').hasClass('mode-md')
					) {
						$('.site-header__menu').removeClass('show_submenu');
						$('.menu-item-has-children').removeClass('current_submenu');
					}
				});

				$('.desktop-search').on('click', function () {
					$(this).parent().addClass('searchbar__active');
				});

				// Zoeken en filteren van Locatielist
				var typewatch = (function () {
					var timer = 0;
					return function (callback, ms) {
						clearTimeout(timer);
						timer = setTimeout(callback, ms);
					};
				})();

				$('#locationlist__search').on('keyup', function () {
					typewatch(function () {
						var searchWord = $('#locationlist__search').val();

						if (searchWord.length === 0) {
							$('.locationlist').removeClass('searching');
						} else {
							$('.locationlist').addClass('searching');

							$('.locationlist__location').each(function (index, el) {
								$(el).removeClass('current_search');

								var currentLocation = $(el).attr('data-location');
								var currentLocationLower = currentLocation.toLocaleLowerCase();
								var searchWordLower = searchWord.toLocaleLowerCase();

								if (currentLocationLower.indexOf(searchWordLower) != -1) {
									$(el).addClass('current_search');
								}
							});
						}
					}, 300);
				});

				$('#locationlist__livinglocation').on('click', function () {
					$('.locationlist').toggleClass('livinglocationslist');
				});

				$('.videomodule').on('click', function () {
					$('body').addClass('video-active');
					$('.videomodal__video iframe')[0].src += '&autoplay=1';
				});
				$('.videomodal').on('click', function () {
					$('body').removeClass('video-active');
				});

				$('[data-video]').on('click', function () {
					$(this).addClass('is-active');
					$(this).find('iframe')[0].src += '&autoplay=1';
				});

				$('.faq').on('click', function () {
					if ($(this).hasClass('faq-active')) {
						$('.faq').each(function (index, el) {
							$(el).removeClass('faq-active');
						});
					} else {
						$('.faq').each(function (index, el) {
							$(el).removeClass('faq-active');
						});
						$(this).addClass('faq-active');
					}
				});

				function visitedLocations() {
					if (typeof Storage !== 'undefined') {
						if ($('body').hasClass('locaties')) {
							var location = localStorage.getItem('locationpage');
							if (location == 'visited') {
								$('html, body').animate(
									{
										scrollTop: $('.locationlist__wrapper').offset().top,
									},
									2000
								);
								localStorage.setItem('locationpage', '');
							}
						} else {
							if ($('body').hasClass('single-locaties')) {
								localStorage.setItem('locationpage', 'visited');
							} else {
								localStorage.setItem('locationpage', '');
							}
						}
					}
				}
				visitedLocations();

				objectFitImages();

				var map = null;
				function initMap() {
					$('.acf-map').each(function () {
						var $el = $(this);

						var $markers = $el.find('.marker');
						var args = {
							zoom: 10,
							center: new google.maps.LatLng(0, 0),
							mapTypeId: google.maps.MapTypeId.ROADMAP,
							scrollwheel: false,
						};

						map = new google.maps.Map($el[0], args);

						map.markers = [];

						$markers.each(function () {
							add_marker($(this), map);
						});

						center_map(map);
					});

					function add_marker($marker, map) {
						var latlng = new google.maps.LatLng($marker.data('lat'), $marker.data('lng'));
						var permaLink = $marker.data('permalink');
						var locationTypes = $marker.data('location-types');
						var defaultIcon = '../../app/themes/anton/dist/images/icon_location.png';

						if (locationTypes.includes('dagbesteding-locaties')) {
							defaultIcon = '../../app/themes/anton/dist/images/icon_location_pink.png';
						}

						var marker = new google.maps.Marker({
							position: latlng,
							map: map,
							icon: defaultIcon,
							originalicon: defaultIcon,
							hovericon: '../../app/themes/anton/dist/images/icon_location--hover.png',
							url: permaLink,
						});
						map.markers.push(marker);

						if ($marker.html()) {
							var infowindow = new google.maps.InfoWindow({
								content: $marker.html(),
							});

							google.maps.event.addListener(marker, 'click', function () {
								window.location.href = this.url;
							});
							google.maps.event.addListener(marker, 'mouseover', function () {
								this.setIcon(this.hovericon);
								infowindow.open(map, this);
							});
							google.maps.event.addListener(marker, 'mouseout', function () {
								this.setIcon(this.originalicon);
								infowindow.close();
							});
						}
					}

					function center_map(map) {
						var bounds = new google.maps.LatLngBounds();

						$.each(map.markers, function (i, marker) {
							var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
							bounds.extend(latlng);
						});

						if (map.markers.length == 1) {
							map.setCenter(bounds.getCenter());
							map.setZoom(10);
						} else {
							map.fitBounds(bounds);
						}
					}

					var findOne = function (haystack, arr) {
						return arr.some(function (v) {
							return haystack.indexOf(v) >= 0;
						});
					};
				}
				window.initMap = initMap;

				// $('.mdl-three_columns__column-inner h4').matchHeight();
				// $('.mdl-two_columns__column h4').matchHeight();

				$('.postcode input').on('keyup', function (e) {
					var text = $(this).val();
					var regex = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;
					if (regex.test(text)) {
						$(this).parents('.postcode').addClass('validated');
					} else {
						$(this).parents('.postcode').removeClass('validated');
					}
				});

				$('.name input').on('keyup', function (e) {
					var text = $(this).val();
					var regex = /^[a-z ,.'-]+$/i;
					if (regex.test(text)) {
						$(this).parents('.name').addClass('validated');
					} else {
						$(this).parents('.name').removeClass('validated');
					}
				});

				$('.street input').on('keyup', function (e) {
					var text = $(this).val();
					var regex = /^[a-z ,.'-]+$/i;
					if (regex.test(text)) {
						$(this).parents('.street').addClass('validated');
					} else {
						$(this).parents('.street').removeClass('validated');
					}
				});

				$('.housenumber input').on('keyup', function (e) {
					var text = $(this).val();
					var regex = /^\d+[a-zA-Z ]*$/;
					if (regex.test(text)) {
						$(this).parents('.housenumber').addClass('validated');
					} else {
						$(this).parents('.housenumber').removeClass('validated');
					}
				});

				$('.city input').on('keyup', function (e) {
					var text = $(this).val();
					var regex = /^[a-z ,.'-]+$/i;
					if (regex.test(text)) {
						$(this).parents('.city').addClass('validated');
					} else {
						$(this).parents('.city').removeClass('validated');
					}
				});
			},
		},
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Starter;
			funcname = funcname === undefined ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		},
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
